export default ({ data, title, yLabel }) => ({
  init() {
    const currentHour = new Date().getHours();
    // Create array starting from 23 hours ago
    let hourLabels = Array.from(
      { length: 24 },
      (_, i) => (currentHour - 23 + i + 24) % 24,
    );
    let counts = Array(24).fill(0);

    // Fill counts matching the shifted hours
    data.forEach((item) => {
      const index = (item.hour - (currentHour - 23) + 24) % 24;
      counts[index] = item.count;
    });

    const formattedLabels = hourLabels.map(
      (hour) => `${hour.toString().padStart(2, "0")}:00`,
    );

    new Chart(this.$refs.canvas, {
      type: "line",
      data: {
        labels: formattedLabels,
        datasets: [
          {
            label: yLabel,
            data: counts,
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: true,
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.parsed.y} ${yLabel}`;
              },
            },
          },
          annotation: {
            annotations: {
              morningLine: {
                type: "line",
                xMin: "08:00",
                xMax: "08:00",
                borderColor: "rgba(255, 99, 132, 0.5)",
                borderWidth: 2,
              },
              eveningLine: {
                type: "line",
                xMin: "20:00",
                xMax: "20:00",
                borderColor: "rgba(255, 99, 132, 0.5)",
                borderWidth: 2,
              },
            },
          },
        },
      },
    });
  },
});
