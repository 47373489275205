export default ({ datasets, title }) => ({
  init() {
    const currentHour = new Date().getHours();
    const hourLabels = Array.from(
      { length: 24 },
      (_, i) => (currentHour - 23 + i + 24) % 24,
    );

    const formattedLabels = hourLabels.map(
      (hour) => `${hour.toString().padStart(2, "0")}:00`,
    );

    const colors = {
      moderations: {
        bg: "rgba(54, 162, 235, 0.5)",
        border: "rgba(54, 162, 235, 1)",
      },
      watchlists: {
        bg: "rgba(75, 192, 192, 0.5)",
        border: "rgba(75, 192, 192, 1)",
      },
      bans: { bg: "rgba(255, 99, 132, 0.5)", border: "rgba(255, 99, 132, 1)" },
    };

    const chartDatasets = Object.entries(datasets).map(([key, data]) => {
      const counts = Array(24).fill(0);
      data.forEach((item) => {
        const index = (item.hour - (currentHour - 23) + 24) % 24;
        counts[index] = item.count;
      });

      return {
        label: key.charAt(0).toUpperCase() + key.slice(1),
        data: counts,
        backgroundColor: colors[key].bg,
        borderColor: colors[key].border,
        borderWidth: 1,
        tension: 0.1,
      };
    });

    new Chart(this.$refs.canvas, {
      type: "line",
      data: {
        labels: formattedLabels,
        datasets: chartDatasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: true,
            position: "top",
          },
          annotation: {
            annotations: {
              morningLine: {
                type: "line",
                xMin: "08:00",
                xMax: "08:00",
                borderColor: "rgba(255, 99, 132, 0.25)",
                borderWidth: 2,
              },
              eveningLine: {
                type: "line",
                xMin: "20:00",
                xMax: "20:00",
                borderColor: "rgba(255, 99, 132, 0.25)",
                borderWidth: 2,
              },
            },
          },
        },
      },
    });
  },
});
