export default ({ data, title }) => ({
  init() {
    // Generate a color palette based on number of flags
    const generateColors = (count) => {
      const colors = [];
      for (let i = 0; i < count; i++) {
        const hue = (i * 360) / count;
        colors.push(`hsla(${hue}, 70%, 60%, 0.8)`);
      }
      return colors;
    };

    new Chart(this.$refs.canvas, {
      type: "pie",
      data: {
        labels: data.map((f) => f.name),
        datasets: [
          {
            data: data.map((f) => f.count),
            backgroundColor: generateColors(data.length),
            borderWidth: 2,
            borderColor: "#fff",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: title,
            font: {
              size: 16,
            },
          },
          legend: {
            display: true,
            position: "right",
            labels: {
              padding: 20,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const total = context.dataset.data.reduce((a, b) => a + b, 0);
                const percentage = ((context.parsed / total) * 100).toFixed(1);
                return `${context.label}: ${context.parsed} (${percentage}%)`;
              },
            },
          },
        },
      },
    });
  },
});
